<template>
  <div class="not-found">
    <h1>404 - Page Not Found</h1>
    <p>Redirecting to the home page in 3 seconds...</p>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$router.push('/');
    }, 3000);
  }
}
</script>

<style lang="scss" scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
  h1 {
    font-weight: 600;
    color: #222;
  }
  p {
    color: #222;
  }
}
</style>
